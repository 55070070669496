// Text styling
.or-tac {
  text-align: center;
}

.or--width-100 {
  width: 100%;
}
.or--height-100 {
  height: 100%;
}

/* flex */
.or--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.or--flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.or--align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.or--align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.or--align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.or--justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.or--justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.or--justify-around {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
}

.or--justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.report--vertical {
  display: block; /* Change to 'block' or 'flex' */
  overflow: hidden;
  width: 100%; /* Adjust width as necessary */
  max-width: 120px;
  line-height: 1.5em;
  white-space: normal; /* Ensure whitespace isn't collapsed */
  text-overflow: ellipsis;
}


